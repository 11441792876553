import React, { Fragment, useContext } from 'react';
import isEmpty from 'lodash/isEmpty';
import propTypes from 'prop-types';
import get from 'lodash/get';
import Text, { TextTypes } from 'ni-ui/text';
import { Row, Col } from 'ni-ui/layout';
import { ThemeContext } from 'ni-ui/theme';
import COLORS from 'ni-ui/colors';
import styles from './styles.scss';
import TenantLogo from '../tenant-logo';
import { AppContext } from '../../app-context';

const BrandingHeader = ({ isPaymentPage }) => {
  const { orderDetails, slim } = useContext(AppContext);
  const skipConfirmationPage = get(orderDetails, 'order.merchantAttributes.skipConfirmationPage', 'false');
  const theme = useContext(ThemeContext);
  const brandHeaderPresent = !!theme.merchantLogoUrl || !!theme.backgroundUrl;
  const shouldHide = isPaymentPage ? (slim || (skipConfirmationPage === 'true' &&
    (orderDetails.state === 'CAPTURED' ||
      orderDetails.state === 'FAILED' ||
      orderDetails.state === 'AUTHORISED' ||
      orderDetails.state === 'VERIFIED' ||
      orderDetails.state === 'CANCELLED'))) : false;
  if (shouldHide) {
    return null;
  }
  return (
    <Fragment>
      <Row gutter={false}>
        <Col gutter={false} span={7} sm={12}>
          <div className={styles.pageTitle}>
            <Text textKey={`PAYPAGE_TITLE_${(orderDetails && orderDetails.state) || 'PENDING'}`} type={TextTypes.H1} textColor={COLORS.BLUE} />
          </div>
        </Col>
        <Col gutter={false} sm={12}>
          <div className={styles.pageTitlelogo}>
            <TenantLogo />
          </div>
        </Col>
      </Row>
      {brandHeaderPresent &&
        <div
          className={styles.imageHeader}
          style={{ backgroundColor: theme.backgroundColour }}
        >
          {!isEmpty(theme.backgroundUrl) &&
            <img
              id="logo-background"
              className={styles.logoBackgroundImage}
              src={theme.backgroundUrl}
              alt="logoBackground"
            />}
          {!isEmpty(theme.merchantLogoUrl) &&
            <img
              id="logo"
              className={styles.logo}
              src={theme.merchantLogoUrl}
              alt="logo"
            />}
        </div>}
    </Fragment>
  );
};

BrandingHeader.propTypes = {
  isPaymentPage: propTypes.bool
};
BrandingHeader.defaultProps = {
  isPaymentPage: false
};

export default BrandingHeader;
