import { createOrder } from '../../api';
import { emailValidation, getMinorUnitBy, mobileNumberValidation, nameValidation } from '../../utils';

export const InvalidErrorMessages = {
  firstName: 'INVALID_NAME',
  lastName: 'INVALID_NAME',
  mobileNumber: 'INVALID_MOBILE_NUMBER',
  email: 'INVALID_EMAIL',
  amount: 'INVALID_AMOUNT',
};

export const validationErrorMap = {
  firstName: value => nameValidation(value),
  lastName: value => nameValidation(value),
  mobileNumber: value => mobileNumberValidation(value),
  email: value => emailValidation(value),
  amount: (value, min, max, editable) => {
    if (editable && (max !== 0 || min !== 0)) {
      if (min === 0 && max !== 0) {
        return +value.value * (10 ** getMinorUnitBy(value.countryCode)) <= max;
      }
      if (max === 0 && min !== 0) {
        return +value.value * (10 ** getMinorUnitBy(value.countryCode)) >= min;
      }
      return (min <= +value.value * (10 ** getMinorUnitBy(value.countryCode)) &&
      +value.value * (10 ** getMinorUnitBy(value.countryCode)) <= max);
    }
    return value.value > 0;
  },
};

export const allowPaySubmission = (userData, editable, minAmount, maxAmount) => {
  const {
    firstName, lastName, email, amount, countryCode, mobileNumber
  } = userData;
  const isValidMobileNumber = mobileNumberValidation(mobileNumber);
  const isValidFirstName = nameValidation(firstName);
  const isValidLastName = nameValidation(lastName);
  const isValidEmail = emailValidation(email);

  const isValidAmountValue = validationErrorMap
    .amount(amount, minAmount.value, maxAmount.value, editable);
  const isValidAmount = editable ? isValidAmountValue : true;
  return !!(
    countryCode &&
    isValidMobileNumber &&
    isValidFirstName &&
    isValidLastName &&
    isValidEmail &&
    isValidAmount &&
    amount.value !== 0
  );
};
export const bodyReq = data => ({
  ...data,
  emailAddress: data.email,
  amount: {
    ...data.amount,
    value: data.amount.value * (10 ** getMinorUnitBy(data.amount.countryCode))
  }
});
export const handleCreateOrder = async (invoiceRef, body, setPayNowStatus) => {
  try {
    setPayNowStatus(prev => ({ ...prev, loading: true }));
    const { data } = await createOrder(invoiceRef, bodyReq(body));
    setPayNowStatus(prev => ({ ...prev, loading: false, paymentLink: data.paymentLink }));
  } catch (err) {
    if (err.response && err.response.data.error && err.response.data.error.logoUrl) {
      setPayNowStatus({
        customErrorPage: err.response.data.error,
        isError: true,
        isCustomError: true,
        loading: false
      });
    } else {
      const commonMessage = ' Please contact the merchant for more details.';
      setPayNowStatus({
        isError: true,
        loading: false,
        error: {
          message: err.response.data.errors[0].localizedMessage + commonMessage,
          disableI18n: true
        }
      });
    }
  }
};
