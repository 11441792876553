import React, { Fragment, useContext, useCallback, useEffect, useRef } from 'react';
import { shape, string } from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import startsWith from 'lodash/startsWith';
import { AppContext } from '../../app-context';
import styles from './styles.scss';
import { threeDSAuthentications } from '../../api';

const getBrowserWindowSize = () => '05';

const FrictionlessFrame = ({ threeDsData }) => {
  const {
    outletRef, orderRef, orderDetails, refetchOrder, setContextState
  } = useContext(AppContext);
  const paymentRef = orderDetails.order.paymentReference;

  const {
    threeDSMethodURL, threeDSServerTransID, threeDSMethodData, threeDSMethodNotificationURL
  } = threeDsData;

  const attemptThreeDsAuthentications = useCallback(async (threeDSCompInd) => {
    try {
      const resp = await threeDSAuthentications(
        outletRef, orderRef, paymentRef,
        {
          deviceChannel: 'BRW',
          threeDSCompInd,
          browserInfo: {
            browserAcceptHeader: 'application/json, text/plain, */*',
            browserJavaEnabled: window.navigator.javaEnabled
              ? window.navigator.javaEnabled() : false,
            browserJavascriptEnabled: true,
            browserLanguage: window.navigator.language,
            acceptBrowserLanguages: window.navigator.languages,
            browserColorDepth: window.screen.colorDepth.toString(),
            browserScreenHeight: window.screen.height.toString(),
            browserScreenWidth: window.screen.width.toString(),
            challengeWindowSize: getBrowserWindowSize(),
            browserTZ: new Date().getTimezoneOffset().toString(),
            browserUserAgent: window.navigator.userAgent,
          }
        }
      );
      if (get(resp, 'data.order.threeDs2.transStatus') === 'C') {
        setContextState({
          orderDetails: {
            ...orderDetails,
            order: {
              ...orderDetails.order,
              threeDs2: get(resp, 'data.order.threeDs2')
            }
          }
        });
        return;
      }
      refetchOrder();
    } catch (err) {
      setContextState({
        orderDetails: {
          ...orderDetails,
          state: 'FAILED',
          order: {
            ...orderDetails.order,
            state: 'FAILED',
          }
        }
      });
    }
  }, [outletRef, orderRef, paymentRef]);

  const fingerprintingFrameSubmitRef = useRef();
  useEffect(() => {
    let timeoutId = null;

    const messageReceiver = (message) => {
      const messageData = get(message, 'data', '');
      if (startsWith(messageData, '3DS2_FINGERPRINTING_COMPLETE')) {
        window.clearTimeout(timeoutId);
        const threeDSCompInd = messageData.split(':')[1];
        attemptThreeDsAuthentications(threeDSCompInd);
      }
    };

    if (threeDSMethodURL) {
      window.addEventListener('message', messageReceiver);

      timeoutId = window.setTimeout(() => {
        window.removeEventListener('message', messageReceiver);
        attemptThreeDsAuthentications('N');
      }, 10000);

      fingerprintingFrameSubmitRef.current.click();
    } else {
      attemptThreeDsAuthentications('U');
    }

    return () => {
      if (timeoutId) {
        window.removeEventListener('message', messageReceiver);
        window.clearTimeout(timeoutId);
      }
    };
  }, [attemptThreeDsAuthentications]);

  if (isEmpty(threeDSMethodURL)) {
    return null;
  }
  return (
    <Fragment>
      <form action={threeDSMethodURL} className={styles.hidden} method="post" target="3ds_iframe">
        <input type="hidden" name="threeDSServerTransID" value={threeDSServerTransID} />
        <input type="hidden" name="threeDSMethodNotificationURL" value={threeDSMethodNotificationURL} />
        <input type="hidden" name="threeDSMethodData" value={threeDSMethodData} />
        <input type="submit" ref={fingerprintingFrameSubmitRef} />
      </form>
      <iframe
        style={{
          width: 0,
          height: 0,
          border: 'none',
          position: 'absolute'
        }}
        data-testid="3ds_iframe"
        name="3ds_iframe"
        id="3ds_iframe"
        title="3DS"
        className={styles.threeDSIframe}
      />
    </Fragment>
  );
};

FrictionlessFrame.propTypes = {
  threeDsData: shape({
    threeDSServerTransID: string.isRequired,
    threeDSMethodURL: string,
    threeDSMethodData: string.isRequired,
    threeDSMethodNotificationURL: string.isRequired,
  }).isRequired
};

export default FrictionlessFrame;
