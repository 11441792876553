import React, { useContext, Fragment } from 'react';
import isEmpty from 'lodash/isEmpty';
import { AppContext } from '../app-context';
import { retrieveQueryParams } from '../common/utils';
import OrderInfo from '../components/order-info';
import PaymentMethods from '../components/payment-methods/payment-methods';
import AcceptTabby from '../components/payment-methods/tabby/accept-tabby';
import styles from '../styles.scss';
import RecurringDetails from '../components/recurring-details';

const PaymentPage = () => {
  const { orderDetails, slim } = useContext(AppContext);
  const { paymentId, paymentMethod } = retrieveQueryParams();


  if (orderDetails.state === 'PENDING' && paymentMethod === 'tabby' && !isEmpty(paymentId)) {
    return <AcceptTabby paymentId={paymentId} />;
  }

  return (
    <Fragment>
      {(!slim || slim === 2) && <OrderInfo order={orderDetails} />}
      {(orderDetails.order.recurringDetails && orderDetails.showRecurringSection) &&
        <RecurringDetails order={orderDetails.order} />}
      <div className={styles.verticalMargin4x}>
        <PaymentMethods />
      </div>
    </Fragment>
  );
};

export default PaymentPage;
