import 'ni-ui/styles';
import 'ni-ui/styles.css';
import 'url-search-params-polyfill';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import React, { useMemo } from 'react';
import ReactDOM from 'react-dom';
import { init } from '@sentry/browser';
import get from 'lodash/get';
import { retrieveQueryParams } from './common/utils';
import AppContextProvider from './app-context';
import PaymentPageApp from './paymentPageApp';
import PrePaymentPageApp from './PrepaymentPageApp';

init({
  environment: get(get(window.location, 'hostname', '').split('.'), '0'),
});

const AppComponent = () => {
  const queryParams = useMemo(() => retrieveQueryParams(), []);

  const threeDsIframeRedirect = queryParams.threeDSCompInd || queryParams.base64EncodedCRes;
  if (threeDsIframeRedirect) {
    return null;
  }
  return (
    <AppContextProvider initialState={{ code: queryParams.code, slim: queryParams.slim }}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" >
            <PaymentPageApp />
          </Route>
          <Route exact path="/static-link" >
            { (
              <PrePaymentPageApp />
            ) }
          </Route>
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </BrowserRouter>
    </AppContextProvider>
  );
};

ReactDOM.render(<AppComponent />, document.getElementById('root'));
