import React, { useMemo, Children } from 'react';
import { node, string } from 'prop-types';

const SwitchContext = React.createContext(null);


const Switch = ({ children, condition }) => {
  const value = useMemo(() => {
    const childIds = Children.map(children, child => child.props.id);
    return ({
      condition,
      shouldDefault: !condition || childIds.filter((id) => {
        if (typeof id === 'string') {
          return id === condition;
        }
        // else its an array
        return id.filter(multiId => multiId === condition).length > 0;
      }).length === 0,
    });
  }, [condition]);

  return (
    <SwitchContext.Provider value={value}>
      {children}
    </SwitchContext.Provider>
  );
};

Switch.propTypes = {
  children: node.isRequired,
  condition: string,
};

Switch.defaultProps = {
  condition: null,
};

export { SwitchContext };
export default Switch;
