import React, {
  useState,
  useRef,
  useEffect,
  Fragment,
  useCallback
} from 'react';
import { get, isEmpty, compact, isArray } from 'lodash';

import {
  string,
  arrayOf,
  func,
  bool,
  instanceOf,
  oneOfType,
  shape,
  node
} from 'prop-types';

import Text, { TextTypes } from 'ni-ui/text';
import ArrowCaret from 'ni-ui/icon/lib/ArrowCaret';
import COLORS from 'ni-ui/colors';

import styles from './styles.scss';
import Portal from '../portal';

const computePosition = (containerElement) => {
  if (containerElement) {
    return containerElement.getBoundingClientRect();
  }
  return null;
};

const Anchor = ({
  name,
  anchorRef,
  setIsOpen,
  isOpen,
  selected,
}) => (
  <div
    id="menu"
    role="menu"
    ref={anchorRef}
    className={styles.dropDownAnchor}
    tabIndex="0"
    onClick={() => setIsOpen(!isOpen)}
    onKeyDown={() => setIsOpen(!isOpen)}
  >
    {
        {
          SELECTED: (
            <Fragment>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Text textKey={name} type={TextTypes.LABEL} disableI18n={!name} />
                <Text textKey={selected} disableI18n />
              </div>
              <ArrowCaret
                color={COLORS.BLUE}
                size={8}
                rotate={isOpen ? 0 : 180}
              />
            </Fragment>
          ),
          UNSELECTED: (
            <Fragment>
              <Text textKey={name} />
              <ArrowCaret
                color={COLORS.BLUE}
                size={8}
                rotate={isOpen ? 0 : 180}
              />
            </Fragment>
          )
        }[isEmpty(selected) ? 'UNSELECTED' : 'SELECTED']
      }
  </div>
);

Anchor.propTypes = {
  name: string.isRequired,
  setIsOpen: func.isRequired,
  isOpen: bool.isRequired,
  selected: oneOfType([string.isRequired, arrayOf(string.isRequired)]).isRequired,
  anchorRef: oneOfType([func, shape({ current: instanceOf(Element) })]).isRequired
};

const Option = ({
  option,
  setSelected,
  setIsOpen,
  selected
}) => {
  const onClick = useCallback(() => {
    setSelected(option);
    setIsOpen(false);
  }, [option]);
  return (
    <div
      id="menuitem"
      role="menuitem"
      tabIndex="0"
      onClick={onClick}
      onKeyDown={onClick}
      className={selected === option ? styles.dropDownItemSelected : styles.dropDownItem}
    >
      <Text textColor={COLORS.BLUE} textKey={option} id={option} disableI18n />
    </div>
  );
};

Option.propTypes = {
  option: string,
  setIsOpen: func,
  selected: oneOfType([string.isRequired, arrayOf(string.isRequired)]),
  setSelected: func,
};

Option.defaultProps = {
  option: '',
  setIsOpen: e => e,
  selected: '',
  setSelected: e => e,
};

const DropDown = ({
  name,
  selected,
  setSelected,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef(null);
  const menuRef = useRef(null);
  const boundingBox = computePosition(anchorRef.current);
  const dropdownHeight = 320;
  let style = {
    left: `${get(boundingBox, 'x', 0)}px`,
  };
  if (get(boundingBox, 'bottom', 0) + dropdownHeight > window.innerHeight) {
    style = {
      ...style,
      top: `${(get(boundingBox, 'y', 0) + (window.scrollY - dropdownHeight))}px`,
    };
  } else {
    style = {
      ...style,
      top: `${get(boundingBox, 'bottom', 0) + window.scrollY}px`,
    };
  }

  const handleClick = useCallback(
    (e) => {
      if (
        isOpen &&
        menuRef.current && !menuRef.current.contains(e.target) &&
        anchorRef.current && !anchorRef.current.contains(e.target)
      ) {
        setIsOpen(false);
      }
    },
    [isOpen]
  );

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, [isOpen]);

  const childProps = {
    setSelected, selected, isOpen, setIsOpen
  };
  const childrenWithProps = isArray(children) ?
    React.Children.map(compact(children), child => React.cloneElement(child, childProps)) :
    React.cloneElement(children, childProps);

  return (
    <div style={{ position: 'relative' }}>
      <Anchor
        name={name}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        anchorRef={anchorRef}
        selected={selected}
      />
      <Portal>
        {isOpen && (
          <div className={styles.dropDownMenu} style={style} ref={menuRef}>
            {childrenWithProps}
          </div>
        )}
      </Portal>
    </div>
  );
};

DropDown.propTypes = {
  name: string,
  selected: oneOfType([string.isRequired, arrayOf(string.isRequired)]).isRequired,
  setSelected: func.isRequired,
  children: oneOfType([arrayOf(node), node]).isRequired
};
DropDown.defaultProps = {
  name: '',
};

export default DropDown;
export { Option };
