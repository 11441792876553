import httpClient from 'ni-ui/httpClient';

const getOrder = (currency = '', outletRef, orderRef) =>
  httpClient.get(`/api/outlets/${outletRef}/orders/${orderRef}${currency ? `?currency=${currency}` : ''}`);
const getGooglePayConfig = outletRef =>
  httpClient.get(`/api/outlets/${outletRef}/google-pay/config`, { headers: { hierarchyRef: outletRef } });

const getTheme = outletRef => httpClient.get(`/api/outlets/${outletRef}/branding`);

const authorizeCode = code => httpClient.post(`/api/auth/${code}`);

const getInvoiceData = invoiceRef => httpClient.get(`/invoices/static/${invoiceRef}/prepayment-details`);

const createOrder = (invoiceRef, body) => httpClient.put(`/invoices/static/${invoiceRef}/order`, body, {
  headers: {
    'Content-Type': 'application/vnd.ni-invoice.v1+json'
  }
});

const deleteSavedCard = (outletRef, orderRef, cardToken) =>
  httpClient.put(`/api/outlets/${outletRef}/orders/${orderRef}/deleteSavedCard/${cardToken}`);

const acceptGooglePay = (outletRef, orderRef, paymentRef, body) =>
  httpClient.post(`/api/outlets/${outletRef}/orders/${orderRef}/payments/${paymentRef}/google-pay/accept?isWebPayment=true`, body, {
    headers: {
      'X-Forwarded-For': '1.1.1.1'
    }
  });

const threeDSAuthentications = (outletRef, orderRef, paymentRef, body) =>
  httpClient.post(`/api/outlets/${outletRef}/orders/${orderRef}/payments/${paymentRef}/3ds2/authentications`, body);

const challengeResponse = (outletRef, orderRef, paymentRef, body) =>
  httpClient.post(`/api/outlets/${outletRef}/orders/${orderRef}/payments/${paymentRef}/3ds2/challenge-response`, body);

const installmentOptions = (outletRef, orderRef, paymentRef, body) =>
  httpClient.post(`/api/outlets/${outletRef}/orders/${orderRef}/payments/${paymentRef}/vis/eligibility-check`, body);

const acceptPartialAuth = (outletRef, orderRef, paymentRef) =>
  httpClient.put(`/api/outlets/${outletRef}/orders/${orderRef}/payments/${paymentRef}/partial-auth/accept`);

const declinePartialAuth = (outletRef, orderRef, paymentRef) =>
  httpClient.put(`/api/outlets/${outletRef}/orders/${orderRef}/payments/${paymentRef}/partial-auth/decline`);

export {
  getOrder, getTheme, getInvoiceData, createOrder,
  authorizeCode, threeDSAuthentications, challengeResponse, installmentOptions,
  acceptPartialAuth, declinePartialAuth, getGooglePayConfig, acceptGooglePay, deleteSavedCard
};
