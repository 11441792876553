import { useEffect, useState, useContext } from 'react';
import { get } from 'lodash';
import { AppContext } from '../../../app-context';
import { installmentOptions } from '../../../api';

const useInstallment = ({
  outletRef, orderRef, orderDetails, cardStateRef, selectedPaymentMethodName
}) => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [isReadMore, setIsReadMore] = useState(false);
  const {
    setContextState, hasSelectedInstallmentOption, selectedCurrency
  } = useContext(AppContext);

  const fetchVisData = async ({ cardToken = '', pan = '' }) => {
    try {
      setContextState({ installmentSpinner: true });
      const { data } = await installmentOptions(
        outletRef,
        orderRef,
        orderDetails.order.paymentReference,
        {
          ...(pan ? { pan: pan.replace(new RegExp(' ', 'g'), '') } : {}),
          ...(cardToken ? { cardToken } : {})
        }
      );
      setContextState({ installmentDetails: data, panOrCardToken: pan || cardToken });
      setContextState({ installmentSpinner: false });
    } catch (err) {
      setContextState({ installmentSpinner: false });
      setContextState({ installmentDetails: [] });
    }
  };

  const handleOnReadMoreOrLess = (e) => {
    setIsReadMore(!isReadMore);
    e.preventDefault();
    e.stopPropagation();
  };

  const handleExpand = (index, installmentOption) => {
    if (index === expandedIndex) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
    if (installmentOption.numberOfInstallments === 'FULL' && index !== expandedIndex) {
      setContextState({ selectedInstallmentOption: installmentOption });
    } else {
      setContextState({ selectedInstallmentOption: null });
    }
    setContextState({ hasSelectedInstallmentOption: false });
    setIsReadMore(false);
  };

  const handleInstallmentOptionSelection = (option, acceptedTAndCVersion) => {
    if (option) {
      setContextState({
        selectedInstallmentOption: {
          ...option, termsAndConditions: acceptedTAndCVersion
        },
        hasSelectedInstallmentOption: !hasSelectedInstallmentOption
      });
    } else {
      setContextState({
        selectedInstallmentOption: null,
        hasSelectedInstallmentOption: !hasSelectedInstallmentOption
      });
    }
  };

  useEffect(() => {
    setContextState({ installmentDetails: [], panOrCardToken: '' });
    if (get(orderDetails, 'order.orderCurrency', '') === 'AED' && (selectedCurrency === null || selectedCurrency === 'AED')) {
      if (get(orderDetails, 'order.action', '') === 'PURCHASE') {
        if (selectedPaymentMethodName === 'SAVEDCARD') {
          if (get(get(get(orderDetails, 'order.visSavedCardMatchedCandidates', {}), 'matchedCandidates[0]', {}), 'eligibilityStatus', '') === 'MATCHED') {
            fetchVisData({ cardToken: orderDetails.order.savedCard.cardToken });
          }
        } else if (get(get(cardStateRef, 'current', {}), 'pan', '')) {
          fetchVisData({ pan: get(get(cardStateRef, 'current', {}), 'pan', '') });
        }
      }
    }
  }, [selectedCurrency, selectedPaymentMethodName]);

  return {
    handleExpand,
    expandedIndex,
    hasSelectedInstallmentOption,
    handleInstallmentOptionSelection,
    handleOnReadMoreOrLess,
    isReadMore
  };
};

export default useInstallment;
