import { some } from 'lodash';

const nameRegX = /^[a-z\s]{0,50}$/i;
const ArabicNameRegX = /^[\u0600-\u06FF\u0750-\u077F]{0,50}$/i;
const emailRegex = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
const mobileNumberRegex = /^\d{9,13}$/;
// For Emirates [AANI Validation]
const uaeMobileNoRegex = /^\d{5,13}$/;
const emiratesIdRegex = /^784-[0-9]{4}-[0-9]{7}-[0-9]$/;
const passportIdRegex = /^[0-9CFGHJKLMNPRTVWXYZ]{9}$/;
const aaniEmailRegex = /^[a-zA-Z][a-zA-Z0-9._]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const minorToMajorUnits = (value, minorUnit) => (
  parseFloat((value / (10 ** minorUnit))).toFixed(minorUnit)
);

export const getMinorUnitBy = (currencyCode) => {
  const numberFormatter = Intl.NumberFormat('en', {
    style: 'currency',
    currency: currencyCode || 'AED'
  });

  const { minimumFractionDigits } = numberFormatter.resolvedOptions();
  return minimumFractionDigits;
};

const getMinorUnit = (minorUnit) => {
  if (minorUnit || minorUnit === 0) {
    return minorUnit;
  }
  // default
  return 2;
};

export const amountToMinor = (value, minorUnit) => parseFloat((value *
  (10 ** getMinorUnit(minorUnit))).toFixed(getMinorUnit(minorUnit)));

export const minorToMajorUnitsFromCurrencyCode = (value, currencyCode) => {
  const minimumFractionDigits = getMinorUnitBy(currencyCode);
  return minorToMajorUnits(value, minimumFractionDigits);
};

export const isKsaRegion = () =>
  ['ksa'].some(part => window.location.hostname.includes(part));

export const env = {
  DEV: 'DEV',
  LOCAL: 'LOCAL',
  PROD: 'PROD',
  UAT: 'UAT'
};
export const getEnvironment = () => {
  if (
    some(['localhost'], el => window.location.hostname.indexOf(el) >= 0) ||
    window.location.protocol === 'http:'
  ) {
    return env.LOCAL;
  } else if (some(['dev'], el => window.location.hostname.indexOf(el) >= 0)) {
    return env.DEV;
  } else if (
    some(['uat', 'sandbox'], el => window.location.hostname.indexOf(el) >= 0)
  ) {
    return env.UAT;
  }
  return env.PROD;
};

export function getTotalAmount(order, total, isRecurringEnabled) {
  if (isRecurringEnabled) {
    return `${order.amount.currencyCode} ${order.amount.formattedValue}`;
  }
  return total ? `${total.currencyCode} ${total.formattedValue}` : 0;
}

export const nameValidation =
 value => (value && (nameRegX.test(value) || ArabicNameRegX.test(value)));
export const emailValidation = value => (value && emailRegex.test(value));
export const mobileNumberValidation = value => (value && mobileNumberRegex.test(value));
export const uaeMobileNoRegexValidation = value => (value && uaeMobileNoRegex.test(value));
export const emiratesIdRegexValidation = value => (value && emiratesIdRegex.test(value));
export const passportIdRegexValidation = value => (value && passportIdRegex.test(value));
export const aaniEmailRegexValidation = value => (value && aaniEmailRegex.test(value));
