import { useEffect, useState } from 'react';

const useScript = (src, id, type = 'text/javascript') => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!id) {
      console.error('id is manadatory for useScript hook'); // eslint-disable-line
    }
    const onLoadOrError = () => setLoading(false);
    if (src && id) {
      setLoading(true);
      const { head } = document;
      const script = document.createElement('script');
      script.type = type;
      script.src = src;
      script.id = id;
      script.addEventListener('load', onLoadOrError);
      script.addEventListener('error', onLoadOrError);
      head.appendChild(script);
    }
    return () => {
      const scriptTag = document.getElementById(id);
      if (scriptTag && scriptTag.parentNode && scriptTag.parentNode.removeChild) {
        scriptTag.removeEventListener('load', onLoadOrError);
        scriptTag.removeEventListener('error', onLoadOrError);
        scriptTag.parentNode.removeChild(scriptTag);
      }
    };
  }, [src, id]);

  return loading;
};

export default useScript;
