/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { get, startsWith } from 'lodash';
import { Container, Row, Col } from 'ni-ui/layout';
import Text, { TextTypes } from 'ni-ui/text';
import { CheckBox } from 'ni-ui/input';
import Done from 'ni-ui/icon/lib/Done';
import Colors from 'ni-ui/colors';
import style from './installmentCard.scss';
import { AppContext } from '../../../app-context';
import { minorToMajorUnitsFromCurrencyCode } from '../../../utils';
import Spinner from '../../spinner/spinner';
import InstallmentVisa from '../../../images/installment-visa.svg';

const InstallmentOptions = ({
  installmentList,
  handleExpand,
  expandedIndex,
  hasSelectedInstallmentOption,
  handleInstallmentOptionSelection,
  isReadMore,
  handleOnReadMoreOrLess,
  cardStateRef
}) => {
  const { orderDetails, installmentSpinner, panOrCardToken } = useContext(AppContext);

  const installmentListWithPayInFull = (installmentList && installmentList.length) ?
    [{
      numberOfInstallments: 'FULL',
      costInfo: null,
      termsAndConditions: null,
      vPlanId: '',
    }, ...installmentList]
    : [];

  const cardToken = get(orderDetails, 'order.savedCard.cardToken', '');
  const pan = get(get(cardStateRef, 'current', {}), 'pan', '');

  const handleOnInstallmentTnC = (idx, TnC) => {
    const acceptedTAndCVersion =
    get(TnC.filter(langTnC => (
      startsWith(langTnC.languageCode, orderDetails.order.language)))[0], 'version', false)
      || get(TnC.filter(langTnC => (
        startsWith(langTnC.languageCode, 'en')))[0], 'version', '');
    if (!hasSelectedInstallmentOption) {
      handleInstallmentOptionSelection(installmentListWithPayInFull[idx], acceptedTAndCVersion);
    } else {
      handleInstallmentOptionSelection(null);
    }
  };

  const formatText = (text) => {
    // Replace HTML entities
    let formattedText = text.replace(/&amp;/g, '&')
      .replace(/&lt;/g, '(')
      .replace(/&gt;/g, ')')
      .replace(/&quot;/g, '"')
      .replace(/&#39;/g, "'");

    // Replace line breaks with <br> tags
    formattedText = formattedText.replace(/\n\n/g, '<br>');
    // Convert URLs to hyperlinks
    formattedText = formattedText.replace(
      // eslint-disable-next-line no-useless-escape
      /((http|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?)/g,
      '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>'
    );
    // eslint-disable-next-line react/no-danger
    return <div style={{ color: '#6A7880', fontSize: '12px' }} dangerouslySetInnerHTML={{ __html: formattedText }} />;
  };
  return (
    <React.Fragment>
      {installmentSpinner ? <Spinner /> : null}
      {installmentListWithPayInFull && installmentListWithPayInFull.length &&
      (panOrCardToken === pan || panOrCardToken === cardToken) ? (
        <React.Fragment>
          <Container>
            <span style={{
                  display: 'flex', gap: '4px', alignItems: 'center', marginBottom: '24px', marginLeft: '-14px'
                }}
            >
              <Done size={14} color="green" />
              <Text textKey="ELIGIBLE_FOR_INSTALLMENTS" textColor={Colors.GREEN_CONFIRM} />
            </span>
            <Row>
              {installmentListWithPayInFull.map(({
                numberOfInstallments,
                costInfo,
                termsAndConditions,
                installmentFrequency
              }, index) => (
                <Col gutter={false} span={8} sm={12} md={7} key={numberOfInstallments}>
                  <div
                    className={`${style.installmentCard} ${index === expandedIndex ? style.expanded : ''}`}
                    onClick={() => handleExpand(index, installmentListWithPayInFull[index])}
                  >
                    <div style={{
                        display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between', gap: '6px'
                        }}
                    >
                      <Text
                        textKey={numberOfInstallments === 'FULL' ? 'PAY_IN_FULL' : 'PAY_IN'}
                        type={TextTypes.MENU_LEVEL_2}
                        textColor={Colors.BLUE}
                        values={{ installment: numberOfInstallments }}
                      />
                      {
                        numberOfInstallments === 'FULL' &&
                        <Text
                          type={TextTypes.LABEL_LARGE}
                          textKey="PAY_IN_FULL_AMOUNT"
                          values={{
                            amount: orderDetails.order.amount.formattedValue,
                            currency: orderDetails.order.amount.currencyCode
                        }}
                        />
                      }
                      {costInfo && (
                        <React.Fragment>
                          <Text
                            textKey={`${installmentFrequency}_INSTALLMENT`}
                            values={{
                              installment: minorToMajorUnitsFromCurrencyCode(get(costInfo, 'lastInstallment.totalAmount', 0), get(costInfo, 'currency', '')),
                              currency: get(costInfo, 'currency', '')
                            }}
                          />
                          <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '15px' }}>
                            <Text
                              type={TextTypes.LABEL_LARGE}
                              textKey={`${installmentFrequency}_RATE`}
                              values={{ rate: (get(costInfo, 'annualPercentageRate', 0.00) / 100).toFixed(2) }}
                            />
                            <Text
                              type={TextTypes.LABEL_LARGE}
                              textKey="PROCESSING_FEES"
                              values={{ fees: minorToMajorUnitsFromCurrencyCode(get(costInfo, 'totalUpfrontFees', 0), get(costInfo, 'currency', '')), currency: get(costInfo, 'currency', '') }}
                            />
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                    {index === expandedIndex && termsAndConditions && (
                      <div id="tnc-checkbox" className={`${style.tnc} ${(!isReadMore && !hasSelectedInstallmentOption) && style.disableCheckbox}`}>
                        <div style={{ height: 'fit-content', marginTop: '5px' }}>
                          <CheckBox
                            disabled={!isReadMore && !hasSelectedInstallmentOption}
                            color={Colors.GREEN_CONFIRM}
                            onClick={() => handleOnInstallmentTnC(index, termsAndConditions)}
                            style={{ minWidth: 14, minHeight: 14 }}
                            selected={hasSelectedInstallmentOption}
                          />
                        </div>
                        <span>
                          {!isReadMore ? <Text
                            textKey="STATIC_TNC_TEXT"
                            type={TextTypes.LABEL}
                          /> :
                          <p>
                            <Text
                              type={TextTypes.LABEL}
                              textKey="STATIC_TNC_TEXT"
                            />{formatText(get(termsAndConditions.filter(TnC => (
                            startsWith(TnC.languageCode, orderDetails.order.language)))[0], 'text', false)
                            || get(termsAndConditions.filter(TnC => (
                             startsWith(TnC.languageCode, 'en')))[0], 'text', ''))}
                          </p>}
                          <a style={{ color: Colors.BLUE, textDecoration: 'underline' }} onClick={handleOnReadMoreOrLess}>
                            <Text textKey={!isReadMore ? 'READ_MORE' : 'READ_LESS'} type={TextTypes.LABEL} />
                          </a>
                        </span>
                      </div>
                    )}
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
          <Container>
            <Row>
              <Col span={3} sm={8} md={4}>
                <img src={InstallmentVisa} alt="Visa" style={{ marginTop: '6px' }} />
              </Col>
            </Row>
          </Container>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};
export default InstallmentOptions;

InstallmentOptions.defaultProps = {
  installmentList: [],
  expandedIndex: 0
};

InstallmentOptions.propTypes = {
  installmentList: PropTypes.arrayOf(PropTypes.object),
  handleExpand: PropTypes.func.isRequired,
  expandedIndex: PropTypes.number,
  hasSelectedInstallmentOption: PropTypes.bool.isRequired,
  handleInstallmentOptionSelection: PropTypes.func.isRequired,
  isReadMore: PropTypes.bool.isRequired,
  handleOnReadMoreOrLess: PropTypes.func.isRequired,
  cardStateRef: PropTypes.shape({}).isRequired
};
