import Text, { TextTypes } from 'ni-ui/text';
import React, { useCallback, useContext } from 'react';
import COLORS from 'ni-ui/colors';
import { Option } from 'ni-ui/radio';
import httpClient from 'ni-ui/httpClient';
import { string } from 'prop-types';
import styles from './styles.scss';
import ozowLogoBlack from '../../../images/ozowLogo.png';
import { AppContext } from '../../../app-context';
import { usePaymentMethodSetter } from '../payment-methods';

const Ozow = ({ title }) => {
  const {
    orderDetails,
    outletRef,
    orderRef,
    code,
    setContextState,
  } = useContext(AppContext);


  const onClickPayNow = useCallback(async () => {
    const { data } = await httpClient.post(`/api/outlets/${outletRef}/orders/${orderRef}/payments/${orderDetails.order.paymentReference}/ozow`, {});
    if (data.paymentUrl) {
      window.location.assign(data.paymentUrl);
    } else if (data.errorMessage) {
      throw new ReferenceError(data.errorMessage);
    }
    if (data.cancelled) {
      setContextState({
        orderDetails: {
          ...orderDetails,
          state: 'CANCELLED'
        }
      });
    }
  }, [orderDetails, outletRef, orderRef, code]);

  usePaymentMethodSetter({ onClickPayNow, disablePayNow: false, id: 'OZOW' });

  return (
    <div style={{ display: 'flex' }}>
      <Option disabled={false} id="OZOW" label="Ozow Pay">
        <div className={styles.container}>
          <Text
            textKey={title}
            textColor={COLORS.GREY_VERY_DARK}
            type={TextTypes.MENU_LEVEL_2}
          />
          <div className={styles.iconPadding}>
            <img src={ozowLogoBlack} height="25px" alt="Ozow" />
          </div>
        </div>
      </Option>
    </div>
  );
};

Ozow.propTypes = {
  title: string.isRequired
};

export default Ozow;
