import { useContext, useCallback } from 'react';
import { func, string, bool, oneOfType, arrayOf } from 'prop-types';
import { SwitchContext } from './switch';

const Case = ({ children, id, isDefault }) => {
  const { condition, shouldDefault } = useContext(SwitchContext);

  const shouldDisplay = useCallback((idProp, shouldDefaultProp, isDefaultProp) => {
    if (shouldDefaultProp && isDefaultProp) {
      return true;
    } else if (typeof idProp === 'string') {
      return idProp === condition;
    }
    // else its an array
    return idProp.filter(currId => condition === currId).length > 0;
  }, [condition]);

  return shouldDisplay(id, shouldDefault, isDefault) ? children() : null;
};

Case.propTypes = {
  children: func.isRequired,
  id: oneOfType([string, arrayOf(string)]).isRequired,
  isDefault: bool,
};

Case.defaultProps = {
  isDefault: false
};

export default Case;
