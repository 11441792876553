import { useCallback, useState, useRef } from 'react';

const useStateWithRef = (initialState) => {
  const [state, updateState] = useState(initialState);
  const stateRef = useRef(state);
  const setState = useCallback(data =>
    updateState(oldState => ({
      ...oldState,
      ...data
    })), []);
  stateRef.current = state;
  return [state, setState, stateRef];
};

export default useStateWithRef;
