import { useContext, useEffect, useState } from 'react';
import { acceptGooglePay, getGooglePayConfig } from '../../../api';
import { AppContext } from '../../../app-context';

export const googlePayTransaction = async (
  googlePayResponse, outletRef, orderRef, orderDetails, setIsGooglePayProcessing, setContextState
) => {
  try {
    setIsGooglePayProcessing(true);
    const { data } = await acceptGooglePay(
      outletRef,
      orderRef,
      orderDetails.order.paymentReference,
      { token: googlePayResponse }
    );
    setContextState({
      orderDetails: {
        ...orderDetails,
        state: data.state
      }
    });
  } catch (error) {
    setContextState({
      orderDetails: {
        ...orderDetails,
        state: 'FAILED'
      }
    });
  } finally {
    setIsGooglePayProcessing(false);
  }
};

const useGooglePay = () => {
  const [isGooglePayProcessing, setIsGooglePayProcessing] = useState(false);

  const {
    outletRef, orderDetails, orderRef, setContextState
  } = useContext(AppContext);

  const [googlePayConfig, setGooglePayConfig] = useState(null);

  const handleAcceptGooglePayTransaction = googlePayResponse => googlePayTransaction(
    googlePayResponse,
    outletRef,
    orderRef,
    orderDetails, setIsGooglePayProcessing, setContextState
  );

  useEffect(() => {
    const getConfig = async () => {
      const { data } = await getGooglePayConfig(outletRef);
      setGooglePayConfig(data);
    };
    getConfig();
  }, []);

  return {
    googlePayConfig, isGooglePayProcessing, handleAcceptGooglePayTransaction, orderDetails
  };
};

export default useGooglePay;
