import React from 'react';
import { shape, string, number, bool } from 'prop-types';
import { Col, Row, Container } from 'ni-ui/layout';
import Text from 'ni-ui/text';
import COLORS from 'ni-ui/colors';
import styles from './styles.scss';
import mainStyles from '../../styles.scss';


const LineItem = ({ item, computing }) => (
  <Container key={item.description}>
    <Row>
      <Col span={8} sm={6} md={6}>
        <div style={{ color: COLORS.WHITE }}>
          <Text textKey={`${item.description} x ${item.quantity}`} disableI18n textColor={COLORS.WHITE} />
        </div>
      </Col>
      <Col span={4} sm={6} md={6}>
        <div className={styles.rowText}>
          {computing
            ? <div className={`${mainStyles.animate} ${styles.bodyCopyShimmer}`} />
            : <Text textKey={item.price} disableI18n textColor={COLORS.WHITE} />}
        </div>
      </Col>
    </Row>
    <Row>
      <div className={styles.greyRule} />
    </Row>
  </Container>
);

LineItem.propTypes = {
  item: shape({
    price: string,
    description: string,
    quantity: number,
  }).isRequired,
  computing: bool,
};

LineItem.defaultProps = {
  computing: false,
};

export default LineItem;
