import toLower from 'lodash/toLower';
import get from 'lodash/get';

const validate = (card, cvv) => {
  const currentCard = card;
  const showCvv = get(currentCard, 'recaptureCsc', '') !== 'false';
  if (
    toLower(currentCard.scheme) === 'american_express' ||
    toLower(currentCard.scheme) === 'amex'
  ) {
    if (!/^[0-9]{4}$/.test(cvv)) {
      return ['CVV_ERROR'];
    }
  } else if (showCvv && !/^[0-9]{3}$/.test(cvv)) {
    return ['CVV_ERROR'];
  }
  return [];
};

const formatExpiry = (expiry) => {
  const parts = expiry.split('-');
  return `${parts[1]}/${parts[0].substr(-2)}`;
};
export { validate, formatExpiry };
