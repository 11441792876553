import httpClient from 'ni-ui/httpClient';
import { string } from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../../app-context';
import Fallback from '../../fallback';

const AcceptTabby = ({ paymentId }) => {
  const {
    orderDetails,
    outletRef,
    orderRef,
    setContextState,
  } = useContext(AppContext);

  const acceptPayment = async () => {
    try {
      const { data: resp } = await httpClient.post(`/api/outlets/${outletRef}/orders/${orderRef}/payments/${orderDetails.order.paymentReference}/tabby/accept`, {
        tabbyPaymentId: paymentId,
      });
      setContextState({
        orderDetails: {
          ...orderDetails,
          state: resp.state,
          order: {
            ...resp.order,
          }
        }
      });
    } catch (err) {
      setContextState({
        orderDetails: {
          ...orderDetails,
          state: 'FAILED',
          order: {
            ...orderDetails.order,
            state: 'FAILED',
          }
        }
      });
    }
  };

  useEffect(() => {
    acceptPayment();
  }, []);

  return <Fallback message="NORMAL_LOADING_TEXT" />;
};

AcceptTabby.propTypes = {
  paymentId: string.isRequired,
};

export default AcceptTabby;
