import { useCallback } from 'react';
import httpClient from 'ni-ui/httpClient';


const usePost = (url) => {
  const submit = useCallback(async (body = null, config = null) =>
    httpClient.post(url, body, config), [url]);
  return submit;
};


// eslint-disable-next-line import/prefer-default-export
export { usePost };
