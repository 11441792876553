
import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { Col, Container, Row } from 'ni-ui/layout';
import { ThemeProvider } from 'ni-ui/theme';
import styles from './styles.scss';
import BrandingHeader from './components/branding-header/branding-header';
import locale from './locale';
import InputFields from './components/prepayment-page/InputFields';
import { getInvoiceData } from './api';
import Fallback from './components/fallback';
import { retrieveQueryParams } from './common/utils';
import { ErrorCase } from './components/error/error';

const PrepaymentPageApp = () => {
  const { invoiceRef } = retrieveQueryParams();
  const [payNowStatus, setPayNowStatus] = useState({ isError: false, status: '', loading: false });
  const [loading, setLoading] = useState(false);
  const [brandingInfo, setBrandingInfo] = useState({
    response: {},
    loading: true
  });
  const [error, setError] = useState({ isError: false, message: '' });
  const [invoiceData, setInvoiceData] = useState({});

  const fetchPayPageData = async () => {
    try {
      setLoading(true);
      const { data } = await getInvoiceData(invoiceRef);
      setInvoiceData(data);
      setBrandingInfo({ loading: false, response: data.brandingInfo });
      setLoading(false);
    } catch (err) {
      if (err.response && err.response.data.code === 404) {
        setError({
          message: err.response.data.errors[0].message,
          isError: true,
          disableI18n: true
        });
      } else if (err.response && err.response.data.error && err.response.data.error.logoUrl) {
        setError({
          customErrorPage: err.response.data.error,
          isError: true,
          isCustomError: true,
        });
      } else {
        setError({ message: 'FAILED_TO_LOAD_PAYMENT_DETAILS', isError: true });
      }
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchPayPageData();
  }, [invoiceRef]);

  if (error.isError || payNowStatus.isError) {
    return (
      <IntlProvider locale="en" messages={locale.en} >
        <ErrorCase
          isCustomError={payNowStatus.isError ? payNowStatus.isCustomError : error.isCustomError}
          error={payNowStatus.isError ? payNowStatus.error : error}
          customErrorPage={
            payNowStatus.isError ? payNowStatus.customErrorPage : error.customErrorPage}
        />
      </IntlProvider>
    );
  }
  if (loading) {
    return (
      <Fallback />
    );
  }
  if (payNowStatus.paymentLink) {
    window.location.href = payNowStatus.paymentLink;
  }
  return (
    <IntlProvider locale="en" messages={locale.en} >
      <ThemeProvider themeData={brandingInfo}>
        <div className={styles.page}>
          <div className={`${styles.main} ${styles.mainContainer}`} role="main">
            <Container>
              <Row>
                <Col span={8} sm={12} md={10} offset={{ lg: 4, md: 2, sm: 0 }}>
                  <div style={{ margin: '15px 0' }} >
                    <BrandingHeader />
                  </div>
                  <InputFields
                    {...invoiceData}
                    invoiceRef={invoiceRef}
                    setPayNowStatus={setPayNowStatus}
                    payNowStatus={payNowStatus}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </ThemeProvider>
    </IntlProvider>
  );
};

export default PrepaymentPageApp;
