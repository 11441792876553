import React from 'react';
import { node } from 'prop-types';
import ReactDOM from 'react-dom';

class Portal extends React.Component {
  static propTypes = {
    children: node
  };

  static defaultProps = {
    children: null
  };

  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    document.getElementById('root').appendChild(this.el);
  }

  componentWillUnmount() {
    if (document.getElementById('root')) {
      document.getElementById('root').removeChild(this.el);
    }
  }

  render() {
    const { children } = this.props;
    return ReactDOM.createPortal(children, this.el);
  }
}

export default Portal;
