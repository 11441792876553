const dataArray = [{
  id: '+93',
  name: 'countryCode',
  text: '+93 Afghanistan',
}, {
  id: '+358',
  name: 'countryCode',
  text: '+358 Aland Islands',
}, {
  id: '+355',
  name: 'countryCode',
  text: '+355 Albania',
}, {
  id: '+213',
  name: 'countryCode',
  text: '+213 Algeria',
}, {
  id: '+1684',
  name: 'countryCode',
  text: '+1684 AmericanSamoa',
}, {
  id: '+376',
  name: 'countryCode',
  text: '+376 Andorra',
}, {
  id: '+244',
  name: 'countryCode',
  text: '+244 Angola',
}, {
  id: '+1264',
  name: 'countryCode',
  text: '+1264 Anguilla',
}, {
  id: '+672',
  name: 'countryCode',
  text: '+672 Antarctica',
}, {
  id: '+1268',
  name: 'countryCode',
  text: '+1268 Antigua and Barbuda',
}, {
  id: '+54',
  name: 'countryCode',
  text: '+54 Argentina',
}, {
  id: '+374',
  name: 'countryCode',
  text: '+374 Armenia',
}, {
  id: '+297',
  name: 'countryCode',
  text: '+297 Aruba',
}, {
  id: '+61',
  name: 'countryCode',
  text: '+61 Australia',
}, {
  id: '+43',
  name: 'countryCode',
  text: '+43 Austria',
}, {
  id: '+994',
  name: 'countryCode',
  text: '+994 Azerbaijan',
}, {
  id: '+1242',
  name: 'countryCode',
  text: '+1242 Bahamas',
}, {
  id: '+973',
  name: 'countryCode',
  text: '+973 Bahrain',
}, {
  id: '+880',
  name: 'countryCode',
  text: '+880 Bangladesh',
}, {
  id: '+1246',
  name: 'countryCode',
  text: '+1246 Barbados',
}, {
  id: '+375',
  name: 'countryCode',
  text: '+375 Belarus',
}, {
  id: '+32',
  name: 'countryCode',
  text: '+32 Belgium',
}, {
  id: '+501',
  name: 'countryCode',
  text: '+501 Belize',
}, {
  id: '+229',
  name: 'countryCode',
  text: '+229 Benin',
}, {
  id: '+1441',
  name: 'countryCode',
  text: '+1441 Bermuda',
}, {
  id: '+975',
  name: 'countryCode',
  text: '+975 Bhutan',
}, {
  id: '+591',
  name: 'countryCode',
  text: '+591 Bolivia, Plurinational State of',
}, {
  id: '+387',
  name: 'countryCode',
  text: '+387 Bosnia and Herzegovina',
  search: 'Bosnia and Herzegovina'
}, {
  id: '+267',
  name: 'countryCode',
  text: '+267 Botswana',
}, {
  id: '+55',
  name: 'countryCode',
  text: '+55 Brazil',
}, {
  id: '+246',
  name: 'countryCode',
  text: '+246 British Indian Ocean Territory',
}, {
  id: '+673',
  name: 'countryCode',
  text: '+673 Brunei Darussalam',
}, {
  id: '+359',
  name: 'countryCode',
  text: '+359 Bulgaria',
}, {
  id: '+226',
  name: 'countryCode',
  text: '+226 Burkina Faso',
}, {
  id: '+257',
  name: 'countryCode',
  text: '+257 Burundi',
}, {
  id: '+855',
  name: 'countryCode',
  text: '+855 Cambodia',
}, {
  id: '+237',
  name: 'countryCode',
  text: '+237 Cameroon',
}, {
  id: '+1',
  name: 'countryCode',
  text: '+1 Canada',
}, {
  id: '+238',
  name: 'countryCode',
  text: '+238 Cape Verde',
}, {
  id: '+345',
  name: 'countryCode',
  text: '+345 Cayman Islands',
}, {
  id: '+236',
  name: 'countryCode',
  text: '+236 Central African Republic',
}, {
  id: '+235',
  name: 'countryCode',
  text: '+235 Chad',
}, {
  id: '+56',
  name: 'countryCode',
  text: '+56 Chile',
}, {
  id: '+86',
  name: 'countryCode',
  text: '+86 China',
}, {
  id: '+61',
  name: 'countryCode',
  text: '+61 Christmas Island',
}, {
  id: '+61',
  name: 'countryCode',
  text: '+61 Cocos (Keeling) Islands',
}, {
  id: '+57',
  name: 'countryCode',
  text: '+57 Colombia',
}, {
  id: '+269',
  name: 'countryCode',
  text: '+269 Comoros',
}, {
  id: '+242',
  name: 'countryCode',
  text: '+242 Congo',
}, {
  id: '+243',
  name: 'countryCode',
  text: '+243 Congo, The Democratic Republic of the Congo',
}, {
  id: '+682',
  name: 'countryCode',
  text: '+682 Cook Islands',
}, {
  id: '+506',
  name: 'countryCode',
  text: '+506 Costa Rica',
  search: 'Costa Rica',
}, {
  id: '+225',
  name: 'countryCode',
  text: "+225 Cote d'Ivoire",
  search: "Cote d'Ivoire",
}, {
  id: '+385',
  name: 'countryCode',
  text: '+385 Croatia'
}, {
  id: '+53',
  name: 'countryCode',
  text: '+53 Cuba'
}, {
  id: '+357',
  name: 'countryCode',
  text: '+357 Cyprus'
}, {
  id: '+420',
  name: 'countryCode',
  text: '+420 Czech Republic'
}, {
  id: '+45',
  name: 'countryCode',
  text: '+45 Denmark'
}, {
  id: '+253',
  name: 'countryCode',
  text: '+253 Djibouti'
}, {
  id: '+1767',
  name: 'countryCode',
  text: '+1767 Dominica'
}, {
  id: '+1849',
  name: 'countryCode',
  text: '+1849 Dominican Republic'
}, {
  id: '+593',
  name: 'countryCode',
  text: '+593 Ecuador'
}, {
  id: '+20',
  name: 'countryCode',
  text: '+20 Egypt'
}, {
  id: '+503',
  name: 'countryCode',
  text: '+503 El Salvador'
}, {
  id: '+240',
  name: 'countryCode',
  text: '+240 Equatorial Guinea'
}, {
  id: '+291',
  name: 'countryCode',
  text: '+291 Eritrea'
}, {
  id: '+372',
  name: 'countryCode',
  text: '+372 Estonia'
}, {
  id: '+251',
  name: 'countryCode',
  text: '+251 Ethiopia'
}, {
  id: '+500',
  name: 'countryCode',
  text: '+500 Falkland Islands (Malvinas)'
}, {
  id: '+298',
  name: 'countryCode',
  text: '+298 Faroe Islands'
}, {
  id: '+679',
  name: 'countryCode',
  text: '+679 Fiji'
}, {
  id: '+358',
  name: 'countryCode',
  text: '+358 Finland'
}, {
  id: '+33',
  name: 'countryCode',
  text: '+33 France'
}, {
  id: '+594',
  name: 'countryCode',
  text: '+594 French Guiana'
}, {
  id: '+689',
  name: 'countryCode',
  text: '+689 French Polynesia'
}, {
  id: '+241',
  name: 'countryCode',
  text: '+241 Gabon'
}, {
  id: '+220',
  name: 'countryCode',
  text: '+220 Gambia'
}, {
  id: '+995',
  name: 'countryCode',
  text: '+995 Georgia'
}, {
  id: '+49',
  name: 'countryCode',
  text: '+49 Germany'
}, {
  id: '+233',
  name: 'countryCode',
  text: '+233 Ghana'
}, {
  id: '+350',
  name: 'countryCode',
  text: '+350 Gibraltar'
}, {
  id: '+30',
  name: 'countryCode',
  text: '+30 Greece'
}, {
  id: '+299',
  name: 'countryCode',
  text: '+299 Greenland'
}, {
  id: '+1473',
  name: 'countryCode',
  text: '+1473 Grenada'
}, {
  id: '+590',
  name: 'countryCode',
  text: '+590 Guadeloupe'
}, {
  id: '+1671',
  name: 'countryCode',
  text: '+1671 Guam'
}, {
  id: '+502',
  name: 'countryCode',
  text: '+502 Guatemala'
}, {
  id: '+44',
  name: 'countryCode',
  text: '+44 Guernsey'
}, {
  id: '+224',
  name: 'countryCode',
  text: '+224 Guinea'
}, {
  id: '+245',
  name: 'countryCode',
  text: '+245 Guinea-Bissau'
}, {
  id: '+595',
  name: 'countryCode',
  text: '+595 Guyana'
}, {
  id: '+509',
  name: 'countryCode',
  text: '+509 Haiti'
}, {
  id: '+379',
  name: 'countryCode',
  text: '+379 Holy See (Vatican City State)'
}, {
  id: '+504',
  name: 'countryCode',
  text: '+504 Honduras'
}, {
  id: '+852',
  name: 'countryCode',
  text: '+852 Hong Kong'
}, {
  id: '+36',
  name: 'countryCode',
  text: '+36 Hungary'
}, {
  id: '+354',
  name: 'countryCode',
  text: '+354 Iceland'
}, {
  id: '+91',
  name: 'countryCode',
  text: '+91 India'
}, {
  id: '+62',
  name: 'countryCode',
  text: '+62 Indonesia'
}, {
  id: '+98',
  name: 'countryCode',
  text: '+98 Iran, Islamic Republic of Persian Gulf'
}, {
  id: '+964',
  name: 'countryCode',
  text: '+964 Iraq'
}, {
  id: '+353',
  name: 'countryCode',
  text: '+353 Ireland'
}, {
  id: '+44',
  name: 'countryCode',
  text: '+44 Isle of Man'
}, {
  id: '+972',
  name: 'countryCode',
  text: '+972 Israel'
}, {
  id: '+39',
  name: 'countryCode',
  text: '+39 Italy'
}, {
  id: '+1876',
  name: 'countryCode',
  text: '+1876 Jamaica'
}, {
  id: '+81',
  name: 'countryCode',
  text: '+81 Japan'
}, {
  id: '+44',
  name: 'countryCode',
  text: '+44 Jersey'
}, {
  id: '+962',
  name: 'countryCode',
  text: '+962 Jordan'
}, {
  id: '+77',
  name: 'countryCode',
  text: '+77 Kazakhstan'
}, {
  id: '+254',
  name: 'countryCode',
  text: '+254 Kenya'
}, {
  id: '+686',
  name: 'countryCode',
  text: '+686 Kiribati'
}, {
  id: '+850',
  name: 'countryCode',
  text: "+850 Korea, Democratic People's Republic of Korea"
}, {
  id: '+82',
  name: 'countryCode',
  text: '+82 Korea, Republic of South Korea'
}, {
  id: '+965',
  name: 'countryCode',
  text: '+965 Kuwait'
}, {
  id: '+996',
  name: 'countryCode',
  text: '+996 Kyrgyzstan'
}, {
  id: '+856',
  name: 'countryCode',
  text: '+856 Laos'
}, {
  id: '+371',
  name: 'countryCode',
  text: '+371 Latvia'
}, {
  id: '+961',
  name: 'countryCode',
  text: '+961 Lebanon'
}, {
  id: '+266',
  name: 'countryCode',
  text: '+266 Lesotho'
}, {
  id: '+231',
  name: 'countryCode',
  text: '+231 Liberia'
}, {
  id: '+218',
  name: 'countryCode',
  text: '+218 Libyan Arab Jamahiriya'
}, {
  id: '+423',
  name: 'countryCode',
  text: '+423 Liechtenstein'
}, {
  id: '+370',
  name: 'countryCode',
  text: '+370 Lithuania'
}, {
  id: '+352',
  name: 'countryCode',
  text: '+352 Luxembourg'
}, {
  id: '+853',
  name: 'countryCode',
  text: '+853 Macao'
}, {
  id: '+389',
  name: 'countryCode',
  text: '+389 Macedonia'
}, {
  id: '+261',
  name: 'countryCode',
  text: '+261 Madagascar'
}, {
  id: '+265',
  name: 'countryCode',
  text: '+265 Malawi'
}, {
  id: '+60',
  name: 'countryCode',
  text: '+60 Malaysia'
}, {
  id: '+960',
  name: 'countryCode',
  text: '+960 Maldives'
}, {
  id: '+223',
  name: 'countryCode',
  text: '+223 Mali'
}, {
  id: '+356',
  name: 'countryCode',
  text: '+356 Malta'
}, {
  id: '+692',
  name: 'countryCode',
  text: '+692 Marshall Islands'
}, {
  id: '+596',
  name: 'countryCode',
  text: '+596 Martinique'
}, {
  id: '+222',
  name: 'countryCode',
  text: '+222 Mauritania'
}, {
  id: '+230',
  name: 'countryCode',
  text: '+230 Mauritius'
}, {
  id: '+262',
  name: 'countryCode',
  text: '+262 Mayotte'
}, {
  id: '+52',
  name: 'countryCode',
  text: '+52 Mexico'
}, {
  id: '+691',
  name: 'countryCode',
  text: '+691 Micronesia, Federated States of Micronesia'
}, {
  id: '+373',
  name: 'countryCode',
  text: '+373 Moldova'
}, {
  id: '+377',
  name: 'countryCode',
  text: '+377 Monaco'
}, {
  id: '+976',
  name: 'countryCode',
  text: '+976 Mongolia'
}, {
  id: '+382',
  name: 'countryCode',
  text: '+382 Montenegro'
}, {
  id: '+1664',
  name: 'countryCode',
  text: '+1664 Montserrat'
}, {
  id: '+212',
  name: 'countryCode',
  text: '+212 Morocco'
}, {
  id: '+258',
  name: 'countryCode',
  text: '+258 Mozambique'
}, {
  id: '+95',
  name: 'countryCode',
  text: '+95 Myanmar'
}, {
  id: '+264',
  name: 'countryCode',
  text: '+264 Namibia'
}, {
  id: '+674',
  name: 'countryCode',
  text: '+674 Nauru'
}, {
  id: '+977',
  name: 'countryCode',
  text: '+977 Nepal'
}, {
  id: '+31',
  name: 'countryCode',
  text: '+31 Netherlands'
}, {
  id: '+599',
  name: 'countryCode',
  text: '+599 Netherlands Antilles'
}, {
  id: '+687',
  name: 'countryCode',
  text: '+687 New Caledonia'
}, {
  id: '+64',
  name: 'countryCode',
  text: '+64 New Zealand'
}, {
  id: '+505',
  name: 'countryCode',
  text: '+505 Nicaragua'
}, {
  id: '+227',
  name: 'countryCode',
  text: '+227 Niger'
}, {
  id: '+234',
  name: 'countryCode',
  text: '+234 Nigeria'
}, {
  id: '+683',
  name: 'countryCode',
  text: '+683 Niue'
}, {
  id: '+672',
  name: 'countryCode',
  text: '+672 Norfolk Island'
}, {
  id: '+1670',
  name: 'countryCode',
  text: '+1670 Northern Mariana Islands'
}, {
  id: '+47',
  name: 'countryCode',
  text: '+47 Norway'
}, {
  id: '+968',
  name: 'countryCode',
  text: '+968 Oman'
}, {
  id: '+92',
  name: 'countryCode',
  text: '+92 Pakistan'
}, {
  id: '+680',
  name: 'countryCode',
  text: '+680 Palau'
}, {
  id: '+970',
  name: 'countryCode',
  text: '+970 Palestinian Territory, Occupied'
}, {
  id: '+507',
  name: 'countryCode',
  text: '+507 Panama'
}, {
  id: '+675',
  name: 'countryCode',
  text: '+675 Papua New Guinea'
}, {
  id: '+595',
  name: 'countryCode',
  text: '+595 Paraguay'
}, {
  id: '+51',
  name: 'countryCode',
  text: '+51 Peru'
}, {
  id: '+63',
  name: 'countryCode',
  text: '+63 Philippines'
}, {
  id: '+872',
  name: 'countryCode',
  text: '+872 Pitcairn'
}, {
  id: '+48',
  name: 'countryCode',
  text: '+48 Poland'
}, {
  id: '+351',
  name: 'countryCode',
  text: '+351 Portugal'
}, {
  id: '+1939',
  name: 'countryCode',
  text: '+1939 Puerto Rico'
}, {
  id: '+974',
  name: 'countryCode',
  text: '+974 Qatar'
}, {
  id: '+40',
  name: 'countryCode',
  text: '+40 Romania'
}, {
  id: '+7',
  name: 'countryCode',
  text: '+7 Russia'
}, {
  id: '+250',
  name: 'countryCode',
  text: '+250 Rwanda'
}, {
  id: '+262',
  name: 'countryCode',
  text: '+262 Reunion'
}, {
  id: '+590',
  name: 'countryCode',
  text: '+590 Saint Barthelemy'
}, {
  id: '+290',
  name: 'countryCode',
  text: '+290 Saint Helena, Ascension and Tristan Da Cunha'
}, {
  id: '+1869',
  name: 'countryCode',
  text: '+1869 Saint Kitts and Nevis'
}, {
  id: '+1758',
  name: 'countryCode',
  text: '+1758 Saint Lucia'
}, {
  id: '+590',
  name: 'countryCode',
  text: '+590 Saint Martin'
}, {
  id: '+508',
  name: 'countryCode',
  text: '+508 Saint Pierre and Miquelon'
}, {
  id: '+1784',
  name: 'countryCode',
  text: '+1784 Saint Vincent and the Grenadines'
}, {
  id: '+685',
  name: 'countryCode',
  text: '+685 Samoa'
}, {
  id: '+378',
  name: 'countryCode',
  text: '+378 San Marino'
}, {
  id: '+239',
  name: 'countryCode',
  text: '+239 Sao Tome and Principe'
}, {
  id: '+966',
  name: 'countryCode',
  text: '+966 Saudi Arabia'
}, {
  id: '+221',
  name: 'countryCode',
  text: '+221 Senegal'
}, {
  id: '+381',
  name: 'countryCode',
  text: '+381 Serbia'
}, {
  id: '+248',
  name: 'countryCode',
  text: '+248 Seychelles'
}, {
  id: '+232',
  name: 'countryCode',
  text: '+232 Sierra Leone'
}, {
  id: '+65',
  name: 'countryCode',
  text: '+65 Singapore'
}, {
  id: '+421',
  name: 'countryCode',
  text: '+421 Slovakia'
}, {
  id: '+386',
  name: 'countryCode',
  text: '+386 Slovenia'
}, {
  id: '+677',
  name: 'countryCode',
  text: '+677 Solomon Islands'
}, {
  id: '+252',
  name: 'countryCode',
  text: '+252 Somalia'
}, {
  id: '+27',
  name: 'countryCode',
  text: '+27 South Africa'
}, {
  id: '+211',
  name: 'countryCode',
  text: '+211 South Sudan'
}, {
  id: '+500',
  name: 'countryCode',
  text: '+500 South Georgia and the South Sandwich Islands'
}, {
  id: '+34',
  name: 'countryCode',
  text: '+34 Spain'
}, {
  id: '+94',
  name: 'countryCode',
  text: '+94 Sri Lanka'
}, {
  id: '+249',
  name: 'countryCode',
  text: '+249 Sudan'
}, {
  id: '+597',
  name: 'countryCode',
  text: '+597 Suriname'
}, {
  id: '+47',
  name: 'countryCode',
  text: '+47 Svalbard and Jan Mayen'
}, {
  id: '+268',
  name: 'countryCode',
  text: '+268 Swaziland'
}, {
  id: '+46',
  name: 'countryCode',
  text: '+46 Sweden'
}, {
  id: '+41',
  name: 'countryCode',
  text: '+41 Switzerland'
}, {
  id: '+963',
  name: 'countryCode',
  text: '+963 Syrian Arab Republic'
}, {
  id: '+886',
  name: 'countryCode',
  text: '+886 Taiwan'
}, {
  id: '+992',
  name: 'countryCode',
  text: '+992 Tajikistan'
}, {
  id: '+255',
  name: 'countryCode',
  text: '+255 Tanzania, United Republic of Tanzania'
}, {
  id: '+66',
  name: 'countryCode',
  text: '+66 Thailand'
}, {
  id: '+670',
  name: 'countryCode',
  text: '+670 Timor-Leste'
}, {
  id: '+228',
  name: 'countryCode',
  text: '+228 Togo'
}, {
  id: '+690',
  name: 'countryCode',
  text: '+690 Tokelau'
}, {
  id: '+676',
  name: 'countryCode',
  text: '+676 Tonga'
}, {
  id: '+1868',
  name: 'countryCode',
  text: '+1868 Trinidad and Tobago'
}, {
  id: '+216',
  name: 'countryCode',
  text: '+216 Tunisia'
}, {
  id: '+90',
  name: 'countryCode',
  text: '+90 Turkey'
}, {
  id: '+993',
  name: 'countryCode',
  text: '+993 Turkmenistan'
}, {
  id: '+1649',
  name: 'countryCode',
  text: '+1649 Turks and Caicos Islands'
}, {
  id: '+688',
  name: 'countryCode',
  text: '+688 Tuvalu'
}, {
  id: '+256',
  name: 'countryCode',
  text: '+256 Uganda'
}, {
  id: '+380',
  name: 'countryCode',
  text: '+380 Ukraine'
}, {
  id: '+971',
  name: 'countryCode',
  text: '+971 United Arab Emirates'
}, {
  id: '+44',
  name: 'countryCode',
  text: '+44 United Kingdom'
}, {
  id: '+1',
  name: 'countryCode',
  text: '+1 United States'
}, {
  id: '+598',
  name: 'countryCode',
  text: '+598 Uruguay'
}, {
  id: '+998',
  name: 'countryCode',
  text: '+998 Uzbekistan'
}, {
  id: '+678',
  name: 'countryCode',
  text: '+678 Vanuatu'
}, {
  id: '+58',
  name: 'countryCode',
  text: '+58 Venezuela, Bolivarian Republic of Venezuela'
}, {
  id: '+84',
  name: 'countryCode',
  text: '+84 Vietnam'
}, {
  id: '+1284',
  name: 'countryCode',
  text: '+1284 Virgin Islands, British'
}, {
  id: '+1340',
  name: 'countryCode',
  text: '+1340 Virgin Islands, U.S.'
}, {
  id: '+681',
  name: 'countryCode',
  text: '+681 Wallis and Futuna'
}, {
  id: '+967',
  name: 'countryCode',
  text: '+967 Yemen'
}, {
  id: '+260',
  name: 'countryCode',
  text: '+260 Zambia'
}, {
  id: '+263',
  name: 'countryCode',
  text: '+263 Zimbabwe'
}];

export const countryCodes = dataArray.map(obj => ({
  ...obj,
  search: obj.text.split(' ').slice(1).join(' '),
  id: obj.text
}));

export default dataArray;
