import React from 'react';
import { string } from 'prop-types';
import Text, { TextTypes } from 'ni-ui/text';
import COLORS from 'ni-ui/colors';
import styles from './styles.scss';

const OrderTitle = ({ total }) => (
  <div className={styles.orderTitle} style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
    <div className={styles.totalAmount}>
      <div className={styles.totalDisplay}>
        <Text textKey="TOTAL" textColor={COLORS.WHITE} type={TextTypes.BODY} />
      </div>
      <Text
        textKey={total}
        textColor={COLORS.WHITE}
        type={TextTypes.H3}
        disableI18n
      />
    </div>
  </div>
);

OrderTitle.propTypes = {
  total: string.isRequired
};

export default OrderTitle;
