import React, { useCallback, useContext } from 'react';
import { bool, string } from 'prop-types';
import Text, { TextTypes } from 'ni-ui/text';
import httpClient from 'ni-ui/httpClient';
import COLORS from 'ni-ui/colors';
import { Option } from 'ni-ui/radio';
import { AppContext } from '../../../app-context';
import TabbyIcon from '../../../images/tabby.png';
import { usePaymentMethodSetter } from '../payment-methods';
import styles from './styles.scss';

const Tabby = ({
  tabbyType, description, label, disabled, padding, title,
}) => {
  const {
    orderDetails,
    outletRef,
    orderRef,
    code,
    setContextState,
  } = useContext(AppContext);


  const onClickPayNow = useCallback(async () => {
    const { data } = await httpClient.post(`/api/outlets/${outletRef}/orders/${orderRef}/payments/${orderDetails.order.paymentReference}/tabby`, {
      successUrl: `${window.origin}?code=${code}&payment_method=tabby`,
      cancelUrl: `${window.origin}?code=${code}`,
      failureUrl: `${window.origin}?code=${code}&payment_method=tabby`,
      type: tabbyType
    });
    if (data.webUrl) {
      window.location.assign(data.webUrl);
    } else if (data.errorMessage) {
      throw new ReferenceError(data.errorMessage);
    }
    if (data.cancelled) {
      setContextState({
        orderDetails: {
          ...orderDetails,
          state: 'CANCELLED'
        }
      });
    }
  }, [orderDetails, outletRef, orderRef, tabbyType, code]);

  const selectedPaymentMethod =
    usePaymentMethodSetter({ onClickPayNow, disablePayNow: false, id: tabbyType });

  return (
    <div style={{ display: 'flex', flexDirection: 'column', padding }}>
      <Option disabled={disabled} id={tabbyType} label={label}>
        <div className={styles.tabbyContainer}>
          <Text
            textKey={title}
            textColor={COLORS.GREY_VERY_DARK}
            type={TextTypes.MENU_LEVEL_2}
          />
          <div className={styles.iconPadding}>
            {<img src={TabbyIcon} height="25px" alt="Tabby" />}
          </div>
        </div>
      </Option>
      {description && selectedPaymentMethod === tabbyType &&
        <div style={{ margin: '7px 47px' }}>
          <Text
            textKey={description}
            textColor={COLORS.GREY_MEDIUM}
            type={TextTypes.BODY_COPY}
          />
        </div>}
    </div>
  );
};

Tabby.propTypes = {
  tabbyType: string.isRequired,
  title: string.isRequired,
  description: string,
  label: string.isRequired,
  disabled: bool.isRequired,
  padding: string.isRequired,
};

Tabby.defaultProps = {
  description: null,
};

export default Tabby;
