import React, { useContext, useCallback } from 'react';
// import SamsungPayIcon from 'ni-ui/icon/lib/cards/ChinaUnionPay';
import Text, { TextTypes } from 'ni-ui/text';
import COLORS from 'ni-ui/colors';
import httpClient from 'ni-ui/httpClient';
import $ from 'jquery';
import styles from './styles.scss';
import { usePaymentMethodSetter } from '../payment-methods';
import useScript from '../../hooks/useScript';
import { AppContext } from '../../../app-context';
import SamsungPayIcon from '../../../images/samsung_pay.png';

const samsungPaySDKURI = 'https://d35p4vvdul393k.cloudfront.net/sdk_library/us/stg/ops/pc_gsmpi_web_sdk.js';

const SamsungPay = () => {
  const {
    orderDetails,
    outletRef,
    orderRef,
    setContextState,
  } = useContext(AppContext);

  const isLoadingSDK = useScript(samsungPaySDKURI, 'samsungPaySDK');

  const onClickPayNow = useCallback(async () => {
    const { data } = await httpClient.post(`/api/outlets/${outletRef}/orders/${orderRef}/payments/${orderDetails.order.paymentReference}/samsung-pay`, {});
    window.jQuery = $;
    window.$ = window.jQuery;
    const { SamsungPay: SamsungPaySDK } = window;
    const {
      id,
      href,
      encInfo,
      serviceId,
      callbackUrl,
      cancelUrl,
      cancelled,
    } = data;

    if (cancelled) {
      setContextState({
        orderDetails: {
          ...orderDetails,
          state: 'CANCELLED',
        }
      });
    }

    if (SamsungPaySDK) {
      SamsungPaySDK.connect(
        id,
        href,
        serviceId,
        callbackUrl, // callback url
        cancelUrl, // cancel url
        'uk', // country code
        encInfo.mod,
        encInfo.exp,
        encInfo.keyId
      );
    }
  }, [orderDetails.order, outletRef, orderRef]);

  usePaymentMethodSetter({ onClickPayNow, disablePayNow: isLoadingSDK, id: 'SAMSUNG_PAY' });

  return (
    <div className={styles.samsungPayContainer}>
      <Text
        textKey="SAMSUNG_PAY"
        textColor={COLORS.GREY_VERY_DARK}
        type={TextTypes.MENU_LEVEL_2}
      />
      <div className={styles.iconPadding}>
        {<img src={SamsungPayIcon} width="100px" alt="Sasmung Pay" />}
      </div>
    </div>
  );
};
export { samsungPaySDKURI };
export default SamsungPay;
