import React from 'react';
import styles from './styles.scss';
import mainStyles from '../../../styles.scss';

const CardFallback = () => (
  <div className={styles.cardShimContainer}>
    <div className={styles.cardHeaderShimContainer}>
      <div className={styles.cardShimTitleContainer}>
        <div className={`${mainStyles.animate} ${styles.cardOptionShim}`} />
        <div className={`${mainStyles.animate} ${styles.cardTitleShim}`} />
      </div>
      <div className={`${mainStyles.animate} ${styles.cardTitleShim}`} />
    </div>
    <div className={`${mainStyles.animate} ${styles.panShim}`} />
    <div className={styles.cardMidShimContainer}>
      <div className={`${mainStyles.animate} ${styles.expShim}`} />
      <div className={`${mainStyles.animate} ${styles.expShim}`} />
      <div className={`${mainStyles.animate} ${styles.cvvShim}`} />
    </div>
    <div className={`${mainStyles.animate} ${styles.panShim}`} />
  </div>
);

export default CardFallback;

