import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import InstallmentOptions from './installmentCard';
import useInstallment from './useInstallment';
import { AppContext } from '../../../app-context';
import { PaymentMethodsContext } from '../payment-methods';

const Installment = ({ cardStateRef }) => {
  const {
    orderDetails,
    outletRef, orderRef, installmentDetails, hasSelectedInstallmentOption, selectedCurrency
  } = useContext(AppContext);
  const {
    name: selectedPaymentMethodName
  } = useContext(PaymentMethodsContext);
  const {
    handleExpand,
    expandedIndex,
    handleInstallmentOptionSelection,
    handleOnReadMoreOrLess,
    isReadMore
  } = useInstallment({
    outletRef, orderRef, orderDetails, cardStateRef, selectedPaymentMethodName, selectedCurrency
  });

  return (<InstallmentOptions
    installmentList={get(installmentDetails, 'matchedPlans', [])}
    handleExpand={handleExpand}
    expandedIndex={expandedIndex}
    handleInstallmentOptionSelection={handleInstallmentOptionSelection}
    handleOnReadMoreOrLess={handleOnReadMoreOrLess}
    hasSelectedInstallmentOption={hasSelectedInstallmentOption}
    isReadMore={isReadMore}
    selectedCurrency={selectedCurrency}
    cardStateRef={cardStateRef}
  />);
};

export default Installment;

Installment.propTypes = {
  cardStateRef: PropTypes.shape({}).isRequired
};
