import React, { useContext, Fragment, useState, useEffect, useCallback } from 'react';
import httpClient from 'ni-ui/httpClient';
import { Row, Col } from 'ni-ui/layout';
import PropTypes from 'prop-types';
import { Option } from 'ni-ui/radio';
import AaniLogo from 'ni-ui/icon/lib/cards/Aani';
import get from 'lodash/get';
import Input from 'ni-ui/input';
import Text, { TextTypes } from 'ni-ui/text';
import COLORS from 'ni-ui/colors';
import DropDown, { Option as DropDownOption } from '../../dropdown';
import styles from './styles.scss';
import { usePaymentMethodSetter } from '../payment-methods';
import { AppContext } from '../../../app-context';
import { usePost } from '../../hooks/network';
import { uaeMobileNoRegexValidation, emiratesIdRegexValidation, passportIdRegexValidation, aaniEmailRegexValidation } from '../../../utils';

const Aani = ({ errors, setErrors, shouldExpand }) => {
  const aliasTypes = [
    { id: 'mobileNumber', key: 'MOBILE_NUMBER', name: 'Mobile Number' },
    { id: 'emiratesId', key: 'EMIRATES_ID', name: 'Emirates ID' },
    { id: 'passportId', key: 'PASSPORT_ID', name: 'Passport ID' },
    { id: 'emailId', key: 'EMAIL', name: 'Email ID' }
  ];
  const paymentUrl = window.location.href;
  const { userAgent } = navigator;
  const [selectedAliasType, setAliasType] = useState(aliasTypes[0].name);
  const [selectedAliasTypeInfo, setAliasTypeInfo] = useState({
    id: aliasTypes[0].id,
    key: aliasTypes[0].key
  });
  const [alias, setAlias] = useState('');
  const [isMobileSourceMode, setSourceMode] = useState(false);

  const UAE_COUNTRY_CODE = '+971';
  const isMobileNoType = selectedAliasType === aliasTypes[0].name;
  const isEmiratesId = selectedAliasType === aliasTypes[1].name;

  useEffect(() => {
    setSourceMode(/Mobile|android|iPad|iPhone|iPod/i.test(userAgent));
  }, []);

  const handleErrors = (value) => {
    const isValidMobileNo = uaeMobileNoRegexValidation(value);
    const isValidEmiratesId = emiratesIdRegexValidation(value);
    const isValidPassportId = passportIdRegexValidation(value);
    const isValidEmailRegex = aaniEmailRegexValidation(value);
    let error = '';
    switch (selectedAliasTypeInfo.key) {
      case 'MOBILE_NUMBER':
        if (!isValidMobileNo) error = `${selectedAliasTypeInfo.key}_ERROR`;
        break;
      case 'EMIRATES_ID':
        if (!isValidEmiratesId) error = `${selectedAliasTypeInfo.key}_ERROR`;
        break;
      case 'PASSPORT_ID':
        if (!isValidPassportId) error = `${selectedAliasTypeInfo.key}_ERROR`;
        break;
      case 'EMAIL':
        if (!isValidEmailRegex) error = `${selectedAliasTypeInfo.key}_ERROR`;
        break;
      default:
        break;
    }
    return error;
  };

  const validationError = (value) => {
    const errorMsg = handleErrors(value);
    setErrors(errorMsg);
  };

  const handleInputChange = (value) => {
    let aliasValue = value;
    switch (selectedAliasTypeInfo.key) {
      case 'MOBILE_NUMBER':
        aliasValue = value.replace(/[^0-9]/g, '').substring(0, 13);
        break;
      case 'EMIRATES_ID': {
        const digitalValue = value.replace(/[^0-9]/g, '').substring(0, 15);
        aliasValue = digitalValue.replace(/^(\d{3})(\d)/, '784-$2').replace(/^(\d{3}-\d{4})(\d)/, '$1-$2')
          .replace(/^(\d{3}-\d{4}-\d{7})(\d)/, '$1-$2');
      }
        break;
      case 'PASSPORT_ID':
        aliasValue = value.replace(/[^a-zA-Z0-9]/g, '').substring(0, 9);
        break;
      default:
        break;
    }
    setAlias(aliasValue);
    handleErrors(aliasValue);
  };

  const {
    orderDetails,
    outletRef,
    orderRef,
    setContextState,
  } = useContext(AppContext);

  const pollPaymentStatus = async () => {
    const intervalId = setInterval(async () => {
      const { data } = await httpClient.get(`/api/outlets/${outletRef}/orders/${orderRef}/payments/${orderDetails.order.paymentReference}/aani/status`);
      setContextState({
        orderDetails: {
          ...orderDetails,
          state: data.state,
        },
      });
      if (data.state !== 'PENDING') {
        clearInterval(intervalId);
      }
    }, 6000);
  };

  const submitAaniPayment = usePost(`/api/outlets/${outletRef}/orders/${orderRef}/payments/${orderDetails.order.paymentReference}/aani`);
  const onClickPayNow = useCallback(async () => {
    const { data } = await submitAaniPayment({
      ...(isMobileSourceMode && { source: 'MOBILE_BROWSER' }),
      ...(isMobileSourceMode && { backLink: paymentUrl }),
      aliasType: selectedAliasTypeInfo.key,
      [selectedAliasTypeInfo.id]: !isMobileNoType ? alias : {
        countryCode: UAE_COUNTRY_CODE,
        number: alias
      }
    });
    if (data.cancelled) {
      setContextState({
        orderDetails: {
          ...orderDetails,
          state: 'CANCELLED',
        },
      });
    } else {
      const paymentPath = 'order._embedded.payment[0].aani.deepLinkUrl';
      const deepLinkUrl = get(data, paymentPath, null);
      if (isMobileSourceMode && deepLinkUrl) {
        window.location.assign(deepLinkUrl);
      }
      setContextState({
        orderDetails: {
          ...orderDetails,
          state: data.state,
          order: data.order,
        },
        customFallBackMsg: 'CUSTOM_PENDING_ANNI_PAYMENT_MESSAGE'
      });
    }

    if (data.state === 'PENDING') {
      pollPaymentStatus();
    }
  }, [alias, isMobileSourceMode, submitAaniPayment, orderDetails]);

  const selectedPaymentMethodName = usePaymentMethodSetter({ onClickPayNow, disablePayNow: !(!handleErrors(alias) && alias), id: 'AANI' });

  useEffect(() => {
    setAlias('');
    setErrors('');
    const selectedAliasInfo = aliasTypes.find(ele => ele.name === selectedAliasType);
    setAliasTypeInfo({ id: selectedAliasInfo.id, key: selectedAliasInfo.key });
    if (isEmiratesId) setAlias('784-');
  }, [selectedAliasType, selectedPaymentMethodName]);

  return (
    <Fragment>
      <Option label="AANI" id="AANI">
        <div className={styles.aaniContainer}>
          <Text
            textKey="AANI"
            textColor={COLORS.GREY_VERY_DARK}
            type={TextTypes.MENU_LEVEL_2}
          />
          <div className={styles.iconPadding}>
            <AaniLogo width="35px" data-testid="Aani-svg" />
          </div>
        </div>
      </Option>
      {(selectedPaymentMethodName === 'AANI' || (shouldExpand && selectedPaymentMethodName === 'AANI')) && (
        <Row gutter={false}>
          <Col gutter={false} span={5} md={12} sm={12}>
            <div className={styles.dropdownContainer}>
              <DropDown
                selected={selectedAliasType}
                setSelected={setAliasType}
              >
                {aliasTypes.map(option => (
                  <DropDownOption option={option.name} key={option.key} />
                ))}
              </DropDown>
            </div>
          </Col>

          <Col span={7} md={12} sm={12}>
            <div className={styles.inputContainer}>
              <Row>
                {isMobileNoType && (
                  <Col gutter={false} span={3} md={4} sm={4}>
                    <Input
                      id="countryCode"
                      labelKey="COUNTRY_CODE"
                      value={UAE_COUNTRY_CODE}
                      disabled
                    />
                  </Col>
                )}
                <Col
                  span={isMobileNoType ? 9 : 12}
                  md={isMobileNoType ? 8 : 12}
                  sm={isMobileNoType ? 8 : 12}
                >
                  <Input
                    labelKey={selectedAliasTypeInfo.key}
                    inputMode="numeric"
                    type="text"
                    value={alias}
                    onChange={value => handleInputChange(value)}
                    onBlur={value => validationError(value)}
                    error={!!errors}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

Aani.propTypes = {
  errors: PropTypes.string.isRequired,
  setErrors: PropTypes.func.isRequired,
  shouldExpand: PropTypes.bool.isRequired
};

export default Aani;
