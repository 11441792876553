import React, { useCallback, useContext } from 'react';
import get from 'lodash/get';
import UnionPay from 'ni-ui/icon/lib/cards/ChinaUnionPay';
import Text, { TextTypes } from 'ni-ui/text';
import COLORS from 'ni-ui/colors';
import httpClient from 'ni-ui/httpClient';
import styles from './styles.scss';
import { usePaymentMethodSetter } from '../payment-methods';
import { AppContext } from '../../../app-context';

const ChinaUnionPay = () => {
  const {
    orderDetails,
    outletRef,
    orderRef,
    setContextState,
  } = useContext(AppContext);

  const onClickPayNow = useCallback(async () => {
    const { data } = await httpClient.post(`/api/outlets/${outletRef}/orders/${orderRef}/payments/${orderDetails.order.paymentReference}/china-union-pay`, {});
    const cupPaymentUrl = get(data, 'chinaUnionPayUrl');
    if (cupPaymentUrl) {
      window.location.replace(cupPaymentUrl);
    }
    if (data.cancelled) {
      setContextState({
        orderDetails: {
          ...orderDetails,
          state: 'CANCELLED',
        }
      });
    }
  }, [orderDetails.order, outletRef, orderRef]);

  usePaymentMethodSetter({ onClickPayNow, disablePayNow: false, id: 'CHINA_UNION_PAY' });

  return (
    <div className={styles.cupContainer}>
      <Text
        textKey="UNION_PAY"
        textColor={COLORS.GREY_VERY_DARK}
        type={TextTypes.MENU_LEVEL_2}
      />
      <div className={styles.iconPadding}>
        <UnionPay width="35px" data-testid="union-pay-svg" />
      </div>
    </div>
  );
};

export default ChinaUnionPay;
