import 'intl';
import 'intl/locale-data/jsonp/en';
import 'intl/locale-data/jsonp/ar';
import React, { useEffect, useState, useContext, useMemo } from 'react';
import { addLocaleData, IntlProvider } from 'react-intl';
import en from 'react-intl/locale-data/en';
import ar from 'react-intl/locale-data/ar';
import fr from 'react-intl/locale-data/fr';
import get from 'lodash/get';
import { ThemeProvider } from 'ni-ui/theme';
import { captureException } from '@sentry/browser';
import httpClient from 'ni-ui/httpClient';
import { getOrder, getTheme, authorizeCode } from './api';
import { getFromStorage, setInStorage, getLangDirection, checkIfStorageIsAvailable } from './common/utils';
import { AppContext } from './app-context';
import Pages from './pages';
import Fallback from './components/fallback';
import Error from './components/error';
import './common.scss';
import './styles.scss';
import localeStrings from './locale';
import Switch, { Case } from './components/switch';

addLocaleData([...en, ...ar, ...fr]);

const authInterCeptor = (error) => {
  if (error.response.status === 401) {
    return false;
  }
  return Promise.reject(error);
};
httpClient.Axios.interceptors.response.use(response => response, authInterCeptor);

const getLocaleSettings = (orderDetails) => {
  if (orderDetails && orderDetails.order) {
    return {
      locale: orderDetails.order.language,
      messages: localeStrings[orderDetails.order.language]
    };
  } else if (localeStrings[navigator.language]) {
    return {
      locale: navigator.language,
      messages: localeStrings[navigator.language]
    };
  }
  return { locale: 'en', messages: localeStrings.en };
};

const loadingStates = {
  INITIAL_LOAD: 'INITIAL_LOAD',
  REFETCH: 'REFETCH',
  RECOMPUTE_CURRENCY: 'RECOMPUTE_CURRENCY',
  DONE: 'DONE',
  ERROR: 'ERROR',
  COOKIE_UNAVAILABLE: 'COOKIE_UNAVAILABLE'
};

export const getCodeFromLocalOrSessionStorage = (code) => {
  const storedVal = localStorage.getItem('code');
  if (storedVal !== null && storedVal === code) {
    return storedVal;
  }
  return getFromStorage('code');
};

export const getAuthFromLocalOrSessionStorage = (code) => {
  const storedCode = localStorage.getItem('code');
  const storedAuth = localStorage.getItem('auth');
  if (storedCode !== null && storedCode === code && storedAuth != null) {
    return storedAuth;
  }
  return getFromStorage('auth');
};

export const retrieveAuthCookie = async (code) => {
  if (code) {
    const codeFromStorage = getCodeFromLocalOrSessionStorage(code);
    let authDataFromStorage = getAuthFromLocalOrSessionStorage(code);
    if (!codeFromStorage || codeFromStorage !== code) {
      try {
        const authResponse = await authorizeCode(code);
        if (authResponse.status === 200) {
          setInStorage('code', code);
          setInStorage('auth', JSON.stringify(authResponse.data));
          return authResponse.data;
        }
      } catch (err) {
        captureException(err);
        return null;
      }
    }
    authDataFromStorage = JSON.parse(authDataFromStorage);
    return authDataFromStorage;
  }

  return JSON.parse(getFromStorage('auth'));
};

const App = () => {
  const {
    setContextState,
    code,
    orderDetails,
    setHookState,
    outletRef,
    orderRef,
    loader
  } = useContext(AppContext);
  const [theme, setTheme] = useState(null);

  const fetchPayPageData = async () => {
    try {
      if (!checkIfStorageIsAvailable()) {
        setContextState({
          loader: {
            state: loadingStates.COOKIE_UNAVAILABLE
          }
        });
        return;
      }

      setContextState({
        loader: {
          state: loadingStates.INITIAL_LOAD
        }
      });

      const authResponse = await retrieveAuthCookie(code);
      if (authResponse && authResponse.orderRef) {
        const {
          outletRef: outltRef, orderRef: ordRef, accessToken, paymentToken
        } = authResponse;

        // Set Auth headers
        httpClient.Axios.defaults.headers.get['Access-Token'] = accessToken;
        httpClient.Axios.defaults.headers.get['Payment-Token'] = paymentToken;
        httpClient.Axios.defaults.headers.post['Access-Token'] = accessToken;
        httpClient.Axios.defaults.headers.post['Payment-Token'] = paymentToken;
        httpClient.Axios.defaults.headers.put['Access-Token'] = accessToken;
        httpClient.Axios.defaults.headers.put['Payment-Token'] = paymentToken;

        const orderResponse = await getOrder(null, outltRef, ordRef);
        const themeResponse = await getTheme(outltRef);
        setTheme({ response: themeResponse.data });
        setContextState({
          outletRef: outltRef,
          orderRef: ordRef,
          orderDetails: orderResponse.data,
          selectedCurrency: get(orderResponse, 'data.order.amount.currencyCode', null),
          languageDirection: getLangDirection(get(orderResponse, 'data.order.language', 'en'))
        });
        setContextState({
          loader: {
            state: loadingStates.DONE
          }
        });
      } else {
        setContextState({
          loader: {
            state: loadingStates.ERROR
          }
        });
      }
    } catch (err) {
      captureException(err);
      setContextState({
        loader: {
          state: loadingStates.ERROR
        }
      });
    }
  };

  useEffect(() => { fetchPayPageData(); }, [code]);

  useEffect(() => setContextState({
    refetchOrder: async (currency = '') => {
      try {
        setContextState({
          loader: {
            state: currency ? loadingStates.RECOMPUTE_CURRENCY : loadingStates.REFETCH,
            message: 'INITIAL_LOADER_TEXT'
          }
        });
        const { data } = await getOrder(currency, outletRef, orderRef);
        setHookState(oldState => ({
          ...oldState,
          orderDetails: { ...oldState.orderDetails, ...data }
        }));
        setContextState({
          loader: {
            state: loadingStates.DONE
          }
        });
      } catch (err) {
        setContextState({
          loader: {
            state: loadingStates.ERROR
          }
        });
      }
    },
  }), [getOrder, outletRef, orderRef]);

  const { locale, messages } = useMemo(() =>
    getLocaleSettings(orderDetails), [orderDetails]);

  if (loader.state === loadingStates.COOKIE_UNAVAILABLE) {
    return (
      <IntlProvider
        locale={locale}
        messages={messages}
      >
        <Error messageKey="COOKIES_DISABLED_ERROR" />
      </IntlProvider>
    );
  }

  return (
    <IntlProvider
      locale={locale}
      messages={messages}
    >
      {loader.state === loadingStates.ERROR && !theme
        ? <Error /> :
        <ThemeProvider fallback={() => <Fallback />} themeData={theme || {}}>
          <Switch condition={loader.state}>
            <Case id={loadingStates.INITIAL_LOAD}>
              {() => <Fallback />}
            </Case>
            <Case id={loadingStates.REFETCH}>
              {() => (
                <Fallback
                  message={loader.message}
                />)}
            </Case>
            <Case id={loadingStates.ERROR}>
              {() => <Error />}
            </Case>
            <Case id={[loadingStates.DONE, loadingStates.RECOMPUTE_CURRENCY]}>
              {() => (
                <Pages />
              )}
            </Case>
          </Switch>
        </ThemeProvider>}
    </IntlProvider>
  );
};
export default App;
